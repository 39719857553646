
.colorBlue{
    color: blue;
}
/* 

color codes
rgb(248, 55, 88) primary
#1677ff secodary
rgb(22 101 52) rare


*/

// Base animation keyframes
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideInUp {
  from {
    transform: translateY(30px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideInLeft {
  from {
    transform: translateX(-30px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInRight {
  from {
    transform: translateX(30px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes scaleIn {
  from {
    transform: scale(0.95);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-10px);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

// Utility animation classes
.animate {
  &--fade-in {
    animation: fadeIn 0.6s ease-out forwards;
  }

  &--slide-up {
    animation: slideInUp 0.6s ease-out forwards;
  }

  &--slide-left {
    animation: slideInLeft 0.6s ease-out forwards;
  }

  &--slide-right {
    animation: slideInRight 0.6s ease-out forwards;
  }

  &--scale {
    animation: scaleIn 0.6s ease-out forwards;
  }

  &--bounce {
    animation: bounce 1s ease infinite;
  }

  &--pulse {
    animation: pulse 2s ease infinite;
  }
}

// Animation delays for staggered animations
@for $i from 1 through 5 {
  .delay-#{$i} {
    animation-delay: #{$i * 0.1}s;
  }
}

// Hover animations
.hover-animations {
  // Scale up on hover
  &--scale {
    transition: transform 0.3s ease;
    &:hover {
      transform: scale(1.05);
    }
  }

  // Lift up on hover
  &--lift {
    transition: transform 0.3s ease;
    &:hover {
      transform: translateY(-5px);
    }
  }

  // Glow effect on hover
  &--glow {
    transition: box-shadow 0.3s ease;
    &:hover {
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    }
  }
}

// Page transition animations
.page {
  &-enter {
    opacity: 0;
    transform: translateY(20px);
  }

  &-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 0.3s, transform 0.3s;
  }

  &-exit {
    opacity: 1;
  }

  &-exit-active {
    opacity: 0;
    transition: opacity 0.3s;
  }
}

// Loading animations
.loading {
  &--spinner {
    width: 40px;
    height: 40px;
    border: 3px solid #f3f3f3;
    border-top: 3px solid #3498db;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }

  &--dots {
    .dot {
      display: inline-block;
      width: 8px;
      height: 8px;
      margin: 0 4px;
      background: #3498db;
      border-radius: 50%;
      animation: dots 1.4s infinite ease-in-out;

      &:nth-child(2) {
        animation-delay: 0.2s;
      }

      &:nth-child(3) {
        animation-delay: 0.4s;
      }
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dots {
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}

// Card animations
.card {
  &--hover {
    transition: all 0.3s ease;
    
    &:hover {
      transform: translateY(-10px);
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    }
  }
}

// Button animations
.button {
  &--pulse {
    position: relative;
    
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: inherit;
      animation: buttonPulse 1.5s ease infinite;
    }
  }
}

@keyframes buttonPulse {
  0% {
    transform: scale(1);
    opacity: 0.8;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}

.Testimonials {
  :where(.css-dev-only-do-not-override-1hpnbz2).ant-carousel .slick-dots-bottom{
    bottom: 0 !important;
  }
}